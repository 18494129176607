<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="项目名称"
          color="primary"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmMaItemListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <q-btn color="primary" style="height: 38px" icon="note_add" :loading="PdmMaItemListLoading"
          @click="onAdd">新增</q-btn>
      </div>
      <vxe-grid v-bind="gridOptions" :data="PdmMaItemList" :sort-config="{ multiple: true }" :custom-config="{ storage: true }"
        id="custom-config" :export-config="gridOptions.tableExport" :height="$store.getters.maxPageHeight - 140"
        :loading="PdmMaItemListLoading" highlight-current-row size="mini">
        <template #operate="{ row }">
          <vxe-button flat color="primary" icon="fas  fa-edit" @click="onEdit(row)">编辑</vxe-button>
          <vxe-button flat color="negative" status="danger" icon="fas fa-trash" @click="onDelete(row)">删除</vxe-button>
        </template>
      </vxe-grid>
      <vxe-pager perfect size="mini" :page-sizes="tablePage.pageSizes" v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize" :total="tablePage.totalResult" :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]" @page-change="handlePageChange">
      </vxe-pager>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmSmsshowDlg" persistent>
    <ItemEdit />
  </q-dialog>
</template>
  
<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import ItemEdit from "./itemEdit.vue";
export default {
  components: {
    ItemEdit,
  },
  computed: {
    ...mapState("PdmMaItem", [
      "PdmMaItemList",
      "PdmMaItemListLoading",
      "PdmMaItemEntity",
      "PdmMaItemShowDlg",
    ]),
    ...mapState("SysUser", [
      "UserList"
    ]),

    pdmSmsshowDlg: {
      get() {
        return this.PdmMaItemShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaItem/setPdmMaItemShowDlg", val);
      },
    },
  },
  data() {
    return {
      FilterString: "",
      HospID: "0",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {  
          },
        },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "ItemCode",
            title: "项目编码",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "ItemName",
            title: "项目名称",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "ItemContent",
            title: "项目内容",
            sortable: true,
            align: "left",
            width: 570,
          },
          {
            title: "操作",
            width: 200,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
        ],
        // data: this.PdmMaItemList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmMaItem", [
      "actGetPdmMaItemListByPage",
      "actCreatePdmMaItem",
      "actEditPdmMaItemData",
      "actEditPdmMaItem",
      "actDeletePdmMaItem",
      "actUpdatePdmMaItem"
    ]),
    onLoadData() {
      this.actGetPdmMaItemListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          // this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit("PdmMaItem/setPdmMaItemListLoading", false);
          //this.PdmMaItemListLoading = false;
        });
    },
    onAdd() {
      console.log(`output->`,this.$store.getters["appUser/hospID"])
      this.actCreatePdmMaItem({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmMaItemData(obj);
    },
    onDelete(e) {
      this.actDeletePdmMaItem({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
  